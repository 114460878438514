<template>
  <LayoutWithoutPadding>
    <component
      :is="currentHomePage"
      @sellWithDibbsLearnMore="sellWithDibbsLearnMore"
      :assetSettings="assetSettings"
      :categoriesSettings="categoriesSettings"
      :collectionSettings="collectionSettings"
      :dropsSettings="dropsSettings"
      :sellWithDibbsSettings="sellWithDibbsSettings"
      :valuePropositionSettings="valuePropositionSettings"
    ></component>
  </LayoutWithoutPadding>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Logged from './Logged.vue';
import LoggedOut from './LoggedOut.vue';
import { LayoutWithoutPadding } from '@/components/misc';
import ExternalUrl from '@/enums/ExternalUrl';

import CATEGORIES_QUERY from '@/graphql/queries/Categories.gql';
import COLLECTIONS_QUERY from '@/graphql/queries/Collections.gql';
import PAIRS_QUERY from '@/graphql/queries/Pairs.gql';

import {
  PAIRS_TRENDING,
  PAIRS_NEW_ARRIVALS,
  PAIRS_CARDLADDER_DISCOUNTED,
  PAIRS_WATCHLIST
} from '@/modules/asset/types';

import {
  COLLECTIONS_POPULAR,
  COLLECTIONS_TOP_GAINERS,
  COLLECTIONS_TOP_LOSERS
} from '@/modules/collections/types';

const TRACKING_EVENT_TYPE = {
  [ PAIRS_TRENDING ]: 'volume',
  [ PAIRS_NEW_ARRIVALS ]: 'gain',
  [ PAIRS_CARDLADDER_DISCOUNTED ]: 'loss',
  [ PAIRS_WATCHLIST ]: 'favorites',
};

export default {
  name: 'HomeHOC',
  components: {
    LayoutWithoutPadding,
    Logged,
    LoggedOut,
  },
  data: () => ({
    currentAssetTab: PAIRS_NEW_ARRIVALS,
    currentCollectionTab: COLLECTIONS_POPULAR,
    isAssetLoading: true,
    isCollectionLoading: true,
    isCategoriesLoading: true,
  }),
  mounted() {
    if (!this.globalIsLoading) {
      this.loaded();
    }
    this.$store.dispatch('events/track', { event: 'DASHBOARD_VIEWED' });
  },
  computed: {
    ...mapGetters('asset', [
      'getTrendingAssets',
      'getNewArrivalsAssets',
      'getCardladderDiscountedAssets',
      'getUserWatchList',
    ]),
    ...mapGetters('categories', [
      'getCategoriesList',
    ]),
    ...mapGetters('collections', [
      'getCollectionsPopular',
      'getCollectionsTopGainers',
      'getCollectionsTopLosers',
    ]),
    ...mapGetters('drops', [
      'getCurrentTime',
      'getDrops',
    ]),
    ...mapGetters('topic', [
      'getSellWithDibbsSection',
      'getValuePropositionSection',
    ]),
    currentHomePage() {
      return this.isAuthenticated() ? 'Logged' : 'LoggedOut';
    },
    isWatchListValid(){
      return this.currentHomePage === 'Logged' && !!this.getUserWatchList.length;
    },
    assetTypes(){
      const baseTabs = {
        [ PAIRS_NEW_ARRIVALS ]: {
          type:  PAIRS_NEW_ARRIVALS,
          label: this.$t('asset_section.new_arrivals'),
          sortBy: 'newest',
          assets: this.getNewArrivalsAssets
        },
        [ PAIRS_TRENDING ]: {
          type:  PAIRS_TRENDING,
          label: this.$t('asset_section.popular_assets'),
          sortBy: 'volume',
          assets: this.getTrendingAssets
        },
        [ PAIRS_CARDLADDER_DISCOUNTED ]: {
          type:  PAIRS_CARDLADDER_DISCOUNTED,
          label: this.$t('asset_section.below_auction_prices'),
          sortBy: 'cardladder-discounted',
          assets: this.getCardladderDiscountedAssets
        }
      };

      if (this.isWatchListValid) {
        return {
          [ PAIRS_WATCHLIST ]: {
            type:  PAIRS_WATCHLIST,
            label: this.$t('asset_section.watchlist'),
            assets: this.getUserWatchList
          },
          ...baseTabs
        };
      }

      return {...baseTabs};
    },
    collectionTypes(){
      return {
        [ COLLECTIONS_POPULAR ]: {
          type: COLLECTIONS_POPULAR, 
          label: 'Popular Collections',
          sortBy: 'volume',
          data: this.getCollectionsPopular
        },
        [ COLLECTIONS_TOP_GAINERS ]: {
          type: COLLECTIONS_TOP_GAINERS, 
          label: 'Top Gainers',
          sortBy: 'gain',
          data: this.getCollectionsTopGainers
        },
        [ COLLECTIONS_TOP_LOSERS ]: {
          type: COLLECTIONS_TOP_LOSERS, 
          label: 'Top Losers',
          sortBy: 'loss',
          data: this.getCollectionsTopLosers
        },
      };
    },
    assetSettings(){
      return {
        data: this.assetTypes[this.currentAssetTab].assets,
        isLoading: this.isAssetLoading,
        tab: this.currentAssetTab,
        options: this.createOptionsForTabs(this.assetTypes),
        onTabChange: this.changeAssetTab,
        visible: true,
      };
    },
    categoriesSettings(){
      return {
        data: this.getCategoriesList,
        isLoading: this.isCategoriesLoading,
        visible: !!this.getCategoriesList.length,
      };
    },
    collectionSettings(){
      return {
        data: this.collectionTypes[this.currentCollectionTab].data,
        isLoading: this.isCollectionLoading,
        options: this.createOptionsForTabs(this.collectionTypes),
        onTabChange: this.changeCollectionTab,
        tab: this.currentCollectionTab,
        visible: true,
      };
    },
    dropsSettings(){
      return {
        data: this.getDrops,
        getCurrentTime: this.getCurrentTime,
        isLoading: this.globalIsLoading,
        visible: !!this.getDrops.length,
      };
    },
    sellWithDibbsSettings() {
      return this.getSellWithDibbsSection;
    },
    valuePropositionSettings() {
      return this.getValuePropositionSection;
    },
  },
  methods: {
    ...mapActions('asset', ['setPairs']),
    ...mapActions('categories', ['setCategories']),
    ...mapActions('collections', ['setCollectionByType']),
    changeAssetTab(tab){
      this.currentAssetTab = tab;
      this.getAssetsBasedOnTab(this.assetTypes[tab]);
    },
    changeCollectionTab(tab){
      this.currentCollectionTab = tab;
      this.getCollectionsBasedOnTab(this.collectionTypes[tab]);
    },
    createOptionsForTabs(value){
      return Object.values(value).map(item => ({ label: item.label, type: item.type }));
    },
    async getAssetsBasedOnTab(tab) {
      this.isAssetLoading = true;

      await this.$store.dispatch('events/track', {
        event: 'DASHBOARD_PAIR_GROUP_CHANGED',
        variables: {
          pair_group: TRACKING_EVENT_TYPE[tab.type],
        },
      });

      if (this.currentAssetTab !==  PAIRS_WATCHLIST ) {
        const response = await this.$apollo.query({
          query: PAIRS_QUERY,
          variables: {
            sort: tab.sortBy,
            chartEndTimeSeconds: new Date().getTime() / 1000,
          },
          fetchPolicy: 'no-cache',
        });

        this.setPairs({ type: tab.type, data: response.data.pairs });
      }

      this.isAssetLoading = false;
    },
    async getCollectionsBasedOnTab(tab) {
      this.isCollectionLoading = true;

      const response = await this.$apollo.query({
        query: COLLECTIONS_QUERY,
        variables: {
          sort: tab.sortBy
        },
        fetchPolicy: 'no-cache',
      });

      this.setCollectionByType({
        type: tab.type,
        data: response.data.players
      });

      this.isCollectionLoading = false;
    },
    async getCategories(){
      this.isCategoriesLoading = true;

      const response = await this.$apollo.query({
        query: CATEGORIES_QUERY,
        variables: {},
        fetchPolicy: 'no-cache',
      });

      this.setCategories(response.data.categories);

      this.isCategoriesLoading = false;
    },
    loaded() {
      this.getCollectionsBasedOnTab(this.collectionTypes[this.currentCollectionTab]);
      this.getCategories();
      if (this.isWatchListValid) {
        this.changeAssetTab(PAIRS_WATCHLIST);
      } else {
        this.getAssetsBasedOnTab(this.assetTypes[this.currentAssetTab]);
      }
    },
    sellWithDibbsLearnMore() {
      this.goToExternalPageGlobal(ExternalUrl.SELL_WITH_DIBBS);
    },
  },
  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },
};
</script>
