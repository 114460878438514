<template>
  <IntroVideoSection>
    <BaseText tag="h1" size="headline-large">
      {{ $t('top_section.title') }}
    </BaseText>
    <BaseText tag="h2" size="subheadline-x-medium mt-s16 mb-s40">
      {{ $t('top_section.subtitle') }}
    </BaseText>

    <div class="flex items-center justify-center mb-s40">
      <ButtonV2
        @onClick="goToInternalPageGlobal('/login')"
        :label="$tc('navigation.start_collecting')"
        testId="btn-start_collecting"
        size="medium"
        wide
      />
      <ButtonV2
        @onClick="goToInternalPageGlobal('#sell-with-dibbs')"
        class="ml-s16"
        :label="$tc('navigation.sell_with_dibbs')"
        testId="btn-sell_with_dibbs"
        size="medium"
        wide
      />
    </div>
  </IntroVideoSection>
</template>

<script>
import { BaseText, ButtonV2, IntroVideoSection } from '@/components/misc';

export default {
  name: 'MobileVersion',
  components: {
    BaseText,
    ButtonV2,
    IntroVideoSection,
  }
};
</script>
