<template>
  <main class="flex flex-col w-full">
    <component
      v-for="(section, index) in visibleSections"
      :key="`${section.component}-${index}`"
      :is="section.component"
      v-bind="section.props"
      v-bind:background="`bg-background-${index % 2 !== 0 ? 'page' : 'primary'}`"
      v-on="section.events"
      :class="`bg-background-${index % 2 === 0 ? 'page' : 'primary'}`"
    />
  </main>
</template>

<script>
import { DownloadSection } from '@/components/misc';
import { TopSection } from '@/components/home';
import { FracPackHomeSection } from '@/components/fracpack';
import { assetSettings, AssetSection } from '@/modules/asset';
import { categoriesSettings, CategorySection } from '@/modules/categories';
import { collectionSettings, CollectionsSection } from '@/modules/collections';
import { dropsSettings, DropsSection } from '@/modules/drops';
import { topicSettings, TopicSection } from '@/modules/topic';

export default {
  name: 'HomePage',
  components: {
    AssetSection,
    CategorySection,
    CollectionsSection,
    DropsSection,
    DownloadSection,
    FracPackHomeSection,
    TopicSection,
    TopSection,
  },
  props: {
    assetSettings: {
      type: Object,
      default: () => assetSettings,
    },
    categoriesSettings: {
      type: Object,
      default: () => categoriesSettings,
    },
    collectionSettings: {
      type: Object,
      default: () => collectionSettings,
    },
    dropsSettings: {
      type: Object,
      default: () => dropsSettings,
    },
    sellWithDibbsSettings: {
      type: Object,
      default: () => topicSettings,
    },
    valuePropositionSettings: {
      type: Object,
      default: () => topicSettings,
    },
  },
  computed: {
    sections() {
      return [
        {
          component: 'TopSection',
          props: {
            visible: true,
          },
        },
        {
          component: 'TopicSection',
          props: {
            topicSettings: this.valuePropositionSettings,
            visible: true,
          },
        },
        {
          component: 'AssetSection',
          props: this.assetSettings,
        },
        {
          component: 'DropsSection',
          props: this.dropsSettings,
        },
        {
          component: 'CategorySection',
          props: this.categoriesSettings,
        },
        {
          component: 'FracPackHomeSection',
          props: {
            visible: true,
          },
        },
        {
          component: 'CollectionsSection',
          props: this.collectionSettings,
        },
        {
          component: 'TopicSection',
          props: {
            id: 'sell-with-dibbs',
            topicSettings: this.sellWithDibbsSettings,
            visible: true,
          },
          events: {
            onButtonClick: this.sellWithDibbsLearnMore,
          },
        },
        {
          component: 'DownloadSection',
          props: {
            visible: true,
          },
        },
      ];
    },
    visibleSections() {
      return this.sections.filter((section) => section.props.visible);
    },
  },
  methods: {
    sellWithDibbsLearnMore() {
      this.$emit('sellWithDibbsLearnMore');
    },
  },
};
</script>
