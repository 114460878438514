<template>
  <IntroVideoSection>
    <BaseText tag="h1" size="text-and-buttons__headline">
      {{ $t('top_section.title') }}
    </BaseText>
    <BaseText tag="h2" size="subheadline-x-medium mt-s24">
      {{ $t('top_section.subtitle') }}
    </BaseText>
    <div class="flex items-center justify-center mt-s24">
      <ButtonV2
        @onClick="goToInternalPageGlobal('/login')"
        :label="$tc('navigation.start_collecting')"
        testId="btn-start_collecting"
        size="large"
        wide
      />
      <ButtonV2
        @onClick="goToInternalPageGlobal('#sell-with-dibbs')"
        class="ml-s20"
        testId="btn-sell_with_dibbs"
        :label="$tc('navigation.sell_with_dibbs')"
        size="large"
        wide
      />
    </div>
  </IntroVideoSection>
</template>

<script>
import { BaseText, ButtonV2, IntroVideoSection } from '@/components/misc';

export default {
  name: 'DesktopVersion',
  components: {
    BaseText,
    ButtonV2,
    IntroVideoSection,
  },
};
</script>

<style scoped>
  .text-and-buttons__headline {
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
  }
</style>
