<template>
  <section class="top-section" :aria-label="$t('top_section.title')">
    <DesktopVersion v-if="!isMobileDevice" />
    <MobileVersion v-else />
  </section>
</template>

<script>
import DesktopVersion from './DesktopVersion';
import MobileVersion from './MobileVersion';

export default {
  name: 'TopSection',
  components: {
    DesktopVersion,
    MobileVersion
  },
};
</script>

<style scoped>
  .top-section {
    @apply flex justify-center;
  }
</style>
