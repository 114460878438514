<template>
  <main class="flex flex-col w-full">
    <component
      v-for="(section, index) in visibleSections"
      :key="`${section.component}-${index}`"
      :is="section.component"
      v-bind="section.props"
      v-bind:background="`bg-background-${index % 2 !== 0 ? 'page' : 'primary'}`"
      v-on="section.events"
      :class="`bg-background-${index % 2 === 0 ? 'page' : 'primary'}`"
    />
  </main>
</template>

<script>
import CURRENT_USER_CHART from '@/graphql/queries/CurrentUserChart.gql';

import { DownloadSection } from '@/components/misc';
import { assetSettings, AssetSection } from '@/modules/asset';
import { categoriesSettings, CategorySection } from '@/modules/categories';
import { collectionSettings, CollectionsSection } from '@/modules/collections';
import { dropsSettings, DropsSection } from '@/modules/drops';
import { topicSettings, TopicSection } from '@/modules/topic';
import { WalletSection } from '@/modules/walletv2';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomePage',
  components: {
    AssetSection,
    CategorySection,
    CollectionsSection,
    DownloadSection,
    DropsSection,
    TopicSection,
    WalletSection,
  },
  data: () => ({
    isChartLoading: true,
    selectedChartTab: '1W',
  }),
  props: {
    assetSettings: {
      type: Object,
      default: () => assetSettings,
    },
    categoriesSettings: {
      type: Object,
      default: () => categoriesSettings,
    },
    collectionSettings: {
      type: Object,
      default: () => collectionSettings,
    },
    dropsSettings: {
      type: Object,
      default: () => dropsSettings,
    },
    sellWithDibbsSettings: {
      type: Object,
      default: () => topicSettings,
    },
  },
  computed: {
    ...mapGetters('wallet', [
      'getBalanceChart',
      'getAllChartRanges',
      'getChartRange',
      'getNetCost',
      'getBuyingPower',
      'getHoldings',
      'getActiveOrders',
      'getProfitLossOverall',
    ]),
    sections() {
      return [
        {
          component: 'WalletSection',
          props: {
            cardSettings: this.walletCardSettings,
            chartSettings: this.walletChartSettings,
            visible: true,
          },
          events: {
            changeTab: this.changeChartTab,
          },
        },
        {
          component: 'AssetSection',
          props: this.assetSettings,
        },
        {
          component: 'DropsSection',
          props: this.dropsSettings,
        },
        {
          component: 'CategorySection',
          props: this.categoriesSettings,
        },
        {
          component: 'CollectionsSection',
          props: this.collectionSettings,
        },
        {
          component: 'TopicSection',
          props: {
            topicSettings: this.sellWithDibbsSettings,
            visible: true,
          },
          events: {
            onButtonClick: this.sellWithDibbsLearnMore,
          },
        },
        {
          component: 'DownloadSection',
          props: {
            visible: true,
          },
        },
      ];
    },
    visibleSections() {
      return this.sections.filter((section) => section.props.visible);
    },
    walletChartSettings() {
      let tabList;
      try {
        tabList = Object.keys(this.getAllChartRanges);
      } catch (err) {
        tabList = [];
      }

      return {
        chart: this.getBalanceChart,
        tabList,
        selectedTab: this.selectedChartTab,
        isLoading: this.isChartLoading,
      };
    },
    walletCardSettings() {
      const ordersLength = this.lodashGet(this.getActiveOrders, 'length', 0);

      return {
        netCost: this.getNetCost,
        buyingPower: this.getBuyingPower,
        holdings: this.getHoldings,
        ordersLength,
        ...this.getProfitLossOverall,
      };
    },
  },
  mounted() {
    if (!this.globalIsLoading) {
      this.getChart(this.selectedChartTab);
    }
  },
  methods: {
    ...mapActions('wallet', ['setBalanceChart','setInventoryChange']),
    changeChartTab(value) {
      this.selectedChartTab = value;
    },
    async getChart(tab) {
      this.isChartLoading = true;
      try {
        const chartOption = this.getChartRange(tab);

        const response = await this.$apollo.query({
          query: CURRENT_USER_CHART,
          variables: {
            chartEndTimeSeconds: new Date().getTime() / 1000,
            window: chartOption.window,
            timeframe: chartOption.timeframe,
          },
        });

        this.setBalanceChart(response.data.current_user['inventory_chart']);
        this.setInventoryChange(response.data.current_user['inventory_change']);
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isChartLoading = false;
      }
    },
    sellWithDibbsLearnMore() {
      this.$emit('sellWithDibbsLearnMore');
    },
  },
  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.getChart(this.selectedChartTab);
        });
      }
    },

    selectedChartTab: function (value) {
      this.getChart(value);
    },
  },
};
</script>
